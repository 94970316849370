.mediaBox {
    background: #ffff;
    border-radius: 0.6em;
    box-shadow: 1px 3px  #e5dfdf;
}
.mediaBox:hover {
    border: 1px solid #277AFC;
    box-shadow: 0;
}
.mediaBoxContent{
    margin: 0 auto;
}
.mediaIconImg {
    height: 2em;
    width: 2em;
    margin-right: 1em;
    background: #EDF3FD;
    padding: 0.5em;
    border-radius: 0.4em;
}