.Profile {
    height: 100vh;
}
.ProfileDetails {
    background: linear-gradient(90deg,rgb(218 234 255) 42%, rgb(241 250 255) 100%);
    height: 100%;
}
.ProfileDetailsLogo{ 
    line-height: 1em;
    padding-left: 1em;
    border-bottom: 1px solid #79abfc;
    height: 7%;
}
.ProfileDetailsData{
    height: 92%;
}
.ProfilePicture {
    position: relative;
}
.ProfilePictureImg{
    height: 180px;
    width: 180px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    margin-left: 4%;
    bottom:-90px;
}
.ProfileName{
    margin-left: 1%;
}
.ProfileAbout{
    margin-left: 3%;
    background: #fff;
}
.ProfileInfo {
    height: 70%;
    background: white;
    position: relative;
}
.ProfileConnectNdMessage {
    height: 10%;
    justify-content: space-between;
}
.banner-heading{
    text-align: center;
    padding: 20px;
    font-size: 71px;
    font-weight: 900;
    letter-spacing: 10px;
    color: #3180f9;
}
.ProfileName h4{
    font-weight: 600;
    font-size: 1.4rem;
}
.EducationalInst {
    /* float: right; */
    margin-top: 0.3rem;
}
.ProfileGenralInfo{
    height: auto;
}
.ProfileAbout h4 {
    margin-bottom: 0.2em;
}
.ProfileGenralInfoDiv{
    margin-left:2%;
}
.microSiteImage{
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 0.3rem;
}
.section{
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .section .error{
    font-size: 150px;
    color: #277AFB;
    text-shadow: 
      1px 1px 1px #002a59,    
      2px 2px 1px #022775,
      3px 3px 1px #001559,
      4px 4px 1px #002459,
      5px 5px 1px #002759,
      6px 6px 1px #001f59,
      7px 7px 1px #001c59,
      8px 8px 1px #001c59,
      25px 25px 8px rgba(0,0,0, 0.2);
  }
  
  .page{
    margin: 2rem 0;
    font-size: 20px;
    font-weight: 600;
    color: #444;
  }
  .page-center{
    margin: 2rem 0;
    font-size: 20px;
    font-weight: 600;
    color: #444;
    text-align:center;
  }
  
  .back-home{
    display: inline-block;
    border: 2px solid #222;
    color: #222;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.75rem 1rem 0.6rem;
    transition: all 0.2s linear;
    box-shadow: 0 3px 8px rgba(0,0,0, 0.3);
  }
  .back-home:hover{
    background: #222;
    color: #ddd;
  }
  .bouncing-loader > div {
    width: 16px;
    height: 16px;
    margin: 3rem 0.2rem;
    background: #8385aa;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translate3d(0, -16px, 0);
    }
  }
  
  .bouncing-loader {
    display: flex;
    justify-content: center;
    height: 3rem;
  }
.bounce_div{
        height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}  
/* .ProfileDetailsLogo:hover{
    border: 1px solid #277afb;
    border-radius: 1em;
} */
@media screen and (max-width: 1440px) {
  
  
    .ProfilePictureImg{
        height: 150px;
        width: 150px;
        bottom:-90px;
    }
    .ProfileInfo{
        padding-top:4rem !important;
    }
  
}

@media screen and (max-width: 1200px) {
    .ProfilePictureImg{
        height: 110px;
        width: 110px;
        bottom:-30px;
    }
    .EducationalInst {
        margin-top : 0.3rem
    }
}
@media screen and (max-width: 1024px) {
    .ProfilePicture {
        position: relative;
    }
    .ProfileConnectNdMessage {
        margin-bottom: 1em;
    }
    .banner-heading{
        text-align: center;
        padding: 20px;
        font-size: 42px;
        font-weight: 900;
        letter-spacing: 10px;
        color: #3180f9;
    }
    .ProfileGenralInfo {
        margin-bottom: 1em;
    }
    .ProfileAbout {
        margin-bottom: 0.5em;
    }
    .ProfilePictureImg{
        height: 140px;
        width: 140px;
        bottom: -80px;
    }
    .EducationalInst {
        margin-top : 0.3rem
    }
}
@media screen and (max-width: 768px) {
    .ProfilePictureImg{
        height: 130px;
        width: 130px;
        bottom: -80px;
    }
    .EducationalInst {
        margin-top : 0.3rem
    }
    .ProfileInfo {
        padding-left: 1rem !important;
    }
    .banner-heading{
        text-align: center;
        padding: 20px;
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 6px;
        color: #3180f9;
    }
}
@media screen and (max-width: 425px) {
    .ProfileGenralInfo{
        height: auto;
        margin-left: 0.3rem;
    }
    .ProfileInfo {
        padding-left: 1rem !important;
    }
    .ProfilePictureImg{
        bottom:-70px;
        height: 110px;
        width: 110px;
        border-radius:50%;
        position: absolute;
        z-index: 1;
        margin-left: 9%;
    }
    .ProfileConnectNdMessage {
        justify-content: flex-end;
    }
    .EducationalInst {
        margin-left: 3%;
        margin-top : 0.5rem
    }
    .ProfileGenralInfoDiv{
        padding: 0 1rem !important;
        margin-left: 0;
        word-wrap: break-word;
    }
    .ProfileAboutDiv{
        padding: 0;
    }
    .banner-heading{
        text-align: center;
        padding: 20px;
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 5px;
        color: #3180f9;
    }
   
}