.cardImage{
    height: 50%;
}
.attributesDataDiv {
    height:83%;
    overflow: auto;
    overflow-x: hidden;
}
.indivisualAttrbute{
    cursor:pointer;
    height: 100%;
    border: 1px solid #ffff;
    border-radius: 1em;
    background: #ffff;
    padding-bottom:0.6em ;
    box-shadow: 0.3em 0.3em #c1c7db;
}
.indivisualAttributeImage{
    text-align: center;
}
.indivisualAttributeImage img{
    width: 97%;
    height: min(20vh,220px);
    padding: 0.6em 0.3em;
    border-radius: 1.5em;
}
.indivisualAttrbute .indivisualAttributesPageinfo {
    padding: 0 0.8em;
}
.indivisualAttrbute .indivisualAttributeTitle {
    font-size: 15px;
    word-break: break-all;
}
.indivisualAttrbute .indivisualEventDescr {
    font-size: 15px;
    word-break: break-all;
}