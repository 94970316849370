.pendingTableCls {
    padding-left: 4em;
}
.pendingTableCls Table {
    overflow: auto;
    line-height: 3em;
    box-shadow: 2px 2px #dae8ea;
    border-radius: 2rem;
}
.requestAction{
    margin-left: 1em;
}
.profileDetails {
    /* border-bottom: 1px solid #2eaafc; */
}
.profileDetails h5{
    color :#2eaafc
}
.profileDetails h6 {
    color :#2eaafc;
    word-wrap: break-word;
}
.profileDetails p {
    color :#2eaafc;
    word-wrap: break-word;
    margin:0;
    padding:0;
}
.tableClass {
    height: 73%;
    overflow: auto !important;
    padding: 1rem 1.2rem;
    background-color: #ffff;
    border: 1px solid #cac8c8;
    margin-left: 0.7em;
    border-radius: 0.4rem;
    margin-top: 0.7em;
}
.downloadBtn{
    padding: 0.2rem 0.2rem;
    border: 1px solid gray;
    color: #0d6efd;
    border-radius: 0.4rem;    
}
.tabClassDiv{
    height: 94%;
    overflow: auto;
}
@media screen and (min-width: 1023px) {
    .datePickerSelector{
        text-align: end;
    }
    .tableClass {
        height: 70%;
        overflow: auto !important;
        padding: 0.6rem 0.8rem;
        background-color: #ffff;
        border: 1px solid #cac8c8;
        margin-left: 0.7em;
        border-radius: 0.4rem;
        margin-top: 0.7em;
    }
}
@media screen and (min-width: 1200px) {
    .tableClass {
        height: 70%;
        overflow: auto;
        padding: 0.6rem 0.8rem;
        background-color: #ffff;
        border: 1px solid #cac8c8;
        margin-left: 0.7em;
        border-radius: 0.4rem;
        margin-top: 0.7em;
    }
}
@media screen and (min-width: 1400px) {
    .tableClass {
        height: 73%;
        overflow: auto !important;
        padding: 0.8rem 1rem;
        background-color: #ffff;
        border: 1px solid #cac8c8;
        margin-left: 0.7em;
        border-radius: 0.4rem;
        margin-top: 0.7em;
    }
}