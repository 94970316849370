
.analyticDataDiv , .statisticsDataDiv {
    height:95%;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
}
.analyticsDiv , .analyticsDiv{
    height: 8rem;
    text-align: center;
    background: white;
    border: 1px solid gray;
    border-radius: 1rem;
}

.countspan{
    font-size: 48px;
    font-weight: 600;
    color: #39ACFC;
}