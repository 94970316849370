.channelScreen{
    background: #F1F7FF;
    height: 100vh;
}
.channelScreenBox {
    background: #ffff;
    border: 1px solid rgb(198, 188, 188);
    border-radius: 1rem;
    padding: 1rem 1rem 0 1rem;
    height: 82vh;
    overflow: auto;
}
.channelsInfo {
    cursor: pointer;
}
.channelsList {
    height: 90%;
    overflow: auto;
    margin-bottom: 0.6em;
}
.channelsList .channelsListLogo {
    border-radius: 50%;
    height: 3.5rem;
    width: 3.6rem;
}
.channelsList li {
    border-bottom: 1px solid rgb(198, 196, 196);
}
Table{
    overflow: auto;
    line-height: 2.3em;
    box-shadow: 2px 2px #dae8ea;
    border-radius: 2rem;
}
.totalChannelHeading{
    position:relative;
    top: 0.5rem;
    margin-left: 1rem;
    font-weight: 600;
    font-size: 0.9rem;
}
.tableClassChannel {
    height: 55vh !important;
    overflow:auto !important;
    padding: 1rem 1.2rem;
    background-color:#ffff;
    border: 1px solid #cac8c8;
    margin-left: 1rem;
    border-radius: 1.3rem;
}
.buttonsPendingCreateChannel {
    text-align: end;
}
.channelsListDiv {
    height:80%
}
.channelDiscInputBox {
    min-height:2.8rem;
    width: 100%;
    border-radius: 0.7rem;
    border: 1px solid #e0e0e0;
    padding-left: 1rem;
    font-size: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.3rem;
}
.channelDiscInputBox:focus-visible {
    border-radius: 1rem;
    outline:1px solid #c0cfe6
}
@media screen and (max-width: 1024px) {
    .channelsListDiv{
        height:76%;
    }
}
@media screen and (max-width: 1400px) {
    .channelsListDiv{
        height:76%;
    }
}