.roleIndDiv{
    background-color: rgb(218, 207, 228);
    height: 2.3rem;
    border: 1px solid gainsboro;
    border-radius: 0.6rem;
}
.tableAddRoleClass{
    height: 95%;
    overflow:auto !important;
    padding: 1rem 1.2rem;
    background-color:#ffff;
    border: 1px solid #cac8c8;
    margin-left: 1rem;
    border-radius: 1.3rem;
    margin-top: 1em;
}
.editIcon{
    color: green;
    cursor: pointer;
}
.deleteIcon{
    color: red;
    cursor: pointer;
}
.roleItem{
    cursor:default
}
.reBorderClass{
    border: 1px solid red !important;
}
.reBorderClass:focus{
    border: 1px solid red;
    border-color: red !important;
    box-shadow: 0 0 0 0.25rem rgb(253 13 87 / 25%) !important;
}
.warningRight{
    text-align: center;
}