.userActivity {
    background: #ffff;
    border-radius: 1em;
    width: 95%;
    margin-top: 2em;
    height: 100%;
}
.userActivityContainer{
    padding: 2em;
}
.adminTableData{
    border: 1px solid #dfd8d8;
    border-radius: 0.4em;
    height: 100%;
}
.adminTableData table {
    line-height: 2.5em;
}
.adminTableData table thead {
    height: 100%;
}
.adminTableData .viewsOnPost {
    margin-left: 1em;
    padding: 0.3em 0.3em;
    border: 1px solid #277AFC;
    border-radius: 0.4em;
}