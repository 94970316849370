.adminPannelOption{ 
    line-height: 1em;
    padding-left: 1em;
    width: 85%;
}
.adminPannelNameLogo{
    line-height: 1em;
    padding-left: 1em;
    width: 85%;
    cursor:default
}
.adminPannelOption:hover{
    border: 1px solid white;
    border-radius: 1em;
}
.adminPannel{
    background: #F1F7FF;
    height: 100vh;
}
.adminPanelOptionTitle{
    margin-left: 1.5em;
    line-height: 1.5em;
}
.profileInfo{
    margin-left: 1.3em;
    /* margin-top: 3em; */
}
.profileName{
    margin-left: 1.3em;
    font-size: 1em;
    cursor: pointer;
    flex: 1;
}
.profileNameDetails{
    margin-left: 1.3em;
    font-size: 1em;
    cursor:default
}
.adminPannelLogout {
    padding-top: 0.6em;
    height:3.5rem;
    width: 85%;
}
.submenutLogoutOption{
    height: 85%;
}
.adminPannelLogout:hover{
    border: 1px solid white;
    border-radius: 1em;
}
.adminPannelMedisetterLogo{
    height: 1.3em;
    width: 1.3em;
}
.adminPannelMedisetterOptionLogo {
    height: 1em;
    width: 1em;    
}
.adminPannelMedisetterHeading {
    color:#ffff;
    font-size: 1.1em;
    margin-left: 0.8em;
}
.adminPannelMedisetterOptionDiv{
    font-size: 1.1rem;
}
.profileNameLogoImg{
    border-radius: 50%;
    height: 1.7rem;
    width: 1.7rem;
    cursor: pointer;
}
.adminPannelLogoutDiv{
    font-size: 1em;
}
.adminPannelLogoutBtn{
    height: 1.7em;
    width: 2em;
}
.leftProfileImagePic{
    height: 2em;
    width: 2em;
    border-radius: 50%;
    /* border: 1px solid rgb(237, 229, 229); */
}
::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    border-radius:1em;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(220, 209, 209) !important;
  }
  ::-webkit-scrollbar-track {
    background: transparent!important;
  }
  .profileNameLogo{
    cursor: pointer;
  }
  .profiileImageLogo {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
  }
  ul, li { 
    list-style: none; 
    margin: 0; 
    padding: 0; 
}
  .listOfIndData{
    cursor: pointer;
    height: 3rem;
    border: 1px solid rgb(182, 182, 182);
    background-color: #f2f2f2;
    border-radius: 1rem;
    align-items: center;
    display: flex;
    padding-left: 1rem;
  }
  .listOfIndData:hover{
    background-color: #DAE9FF;
    cursor: pointer;
    border:1px solid #c0cfe6;
}
 /* .adminPannelLogout .active {
    background :#96bef0 !important;
    width: 100%;
  } */
@media screen and (max-width: 1024px) {
    .adminPanelOptionTitle{
        line-height: 1.5em;
    }
    
}
@media screen and (max-width: 1399px) {
    .adminPannelNameLogo{
        line-height: 1em;
        padding-left: 0.4em;
        width: 85%;
        cursor:default
    }
    .adminPannelOption{ 
        line-height: 1em;
        padding-left: 0.4em;
        width: 85%;
    }
    .profileInfo{
        margin-left: 0.3em;
    }
    .profileName{
        margin-left: 1em;
        font-size: 1em;
    }
    .adminPanelOptionTitle{
        margin-left: 1em;
        line-height: 1.5em;
    }
    .adminPannelMedisetterHeading {
        color:#ffff;
        font-size: 1.1em;
        margin-left: 0.3em;
    }
}