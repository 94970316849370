.editIcon{
    color: green;
    cursor: pointer;
}
.tableAllUserClass{
    height: 95%;
    overflow:auto !important;
    padding: 1rem 1.2rem;
    background-color:#ffff;
    border: 1px solid #cac8c8;
    margin-left: 1rem;
    border-radius: 1.3rem;
    margin-top: 1em;
}