.profileImagePicDivPost {
    width: 100%;
    cursor: pointer;
}
.profileImagePicDivPost span{
    text-align: center;
}
.uploadedPostMediaViewDiv{
    border-radius: 0.7rem;
    border: 1px solid #e0e0e0;
    /* padding-left: 1.5em;
    padding-right: 1.5em; */
    width: 65%;
    margin: 0 auto;
}
.targeting_parameter{
    width:16px;
}
.adminUploadVideoForm{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.uploadAdminPostInputDiv img {
    width:1.4em;
}
.custom_logo{
    position: absolute;
    right: 0;
    top: -8px;
}
.custom-justify{
    position: relative;

}
.feedcat{
    position: relative;
    top: -1rem;
    font-size: 12px;
    font-style: italic;
}
.postProfileImagePic{
    height: 3em;
    width: 3em;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229);
}
.postProfileImagePic1{
    height: 3em;
    width: 3em;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px solid rgb(237, 229, 229);
}
.sponsored{
    font-weight:500;
    margin-right:5px;
}
.shareProfileImagePic{
    height: 2em;
    width: 2em;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229); 
}
.likeProfileImagePic{
    height: 2.3em;
    width: 2.3em;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229);
}
.postRightSideDiv {
    width: 100%;
    display: flex;
}
.postRightSide {
    width: 100%;
}
.postRightSideDiv h6 {
    font-weight: 600;
}
.postTiming {
    font-size: 0.7em;
}
.postRightSideDiv .postLeftSideDiv {
    width: 20%;
    text-align: center;
}
.likeUserDiv{
    border-bottom: 1px solid black;
}
.postImage {
    border: 1px solid #ffff;
    border-radius: 1em;
    width: auto;
    min-width: 100%;
    max-height: 15rem;
    height: auto;
    object-fit: cover;
    background-repeat:no-repeat;
    max-width: 100% !important;
}
.editPostImage {
    border: 1px solid #ffff;
    border-radius: 1em;
    width: auto;
    min-width: 100%;
    max-height: 13rem;
    height: auto;
    object-fit: cover;
    background-repeat:no-repeat;
    max-width: 100% !important;
}
.postVideo {
    border: 1px solid #c0cfe6 ;
    border-radius: 1em;
    width: auto;
    min-width: 100%;
    height:15rem;
}

.reactionCountBtn {
    width: 1em;
    height: 1em;
    margin-right: 0.8em;
}
.reactionCountLabel {
    margin-left: 0.8em;
}
.reactionBtn {
    width: 1.5em;
    margin-right: 0.8em;
}
.reactionOptionDiv {
    border-bottom: 1px solid #e0dada;
    border-top: 1px solid #e0dada;
    height: 2.6em;
    align-items: center;
    position: relative;
    margin-right: 1em;
    cursor: pointer;
}
/* .reactionOptionDivImg:hover{
    background-color: #DAE9FF;
    border:1px solid #c0cfe6;
} */
.reactionOptionDiv img {
    width:1.1em;
}
.postMediaData{
    width: 100%;
    position: relative;
    /* text-align: center; */
}
.reactionCountDiv {
    width: 100%;
    cursor: pointer;
}
.reactionCountDiv span {
    font-size: 0.8rem;
}
.reactionOptionDiv {
    width: 100%;
    height: 2.1rem;
}
.reactionOptionDiv span {
    font-size: 0.8rem;
}
.postEngament{
    font-size: 0.7em;
}
.commentDataDiv{
    height: 94%;
    overflow: auto;
}
.replyToDiv{
    font-size: 0.7em;
}
.replyToDivName{
    color: rgb(40, 130, 252);
}
.replyToDivCancelBtn{
    cursor: pointer;
}
.commentProfileImagePic{
    height: 2em;
    width: 2em;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229);
}
.tagProfileImagePic{
    height: 3em;
    width: 3em;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229);
}
.commentData {
    background-color: #e8e8e8;
    padding: 0.4em;
    border-radius: 0.6em;
}
.tagListData {
    padding: 0.4em;
    background-color: #e8e8e8;
    border-radius: 0.6em;
}
.tagListData h6 {
    margin: 0 0.4em 0.2em 0.4em;
}
.tagListData p {
    font-size: 0.9em ;
    margin: 0 0.4em;
}
.commentData h6 {
    margin: 0 0.4em 0.2em 0.4em;
}
.commentData p {
    font-size: 0.9em ;
    margin: 0 0.4em;
}
.commentLikeShare {
    font-size: 0.8em ;
    margin-left: 4em;
    cursor: pointer;
}
.commentTextArea {
    height: 2rem;
    width: 97%;
    padding: 0.4rem 0.7rem;
    border: 1px solid rgb(167, 147, 147);
    border-radius: 0.6rem;
    overflow: hidden;
    font-size: 0.8em ;
}
.commentTextArea:focus-visible {
    border-radius: 0.6rem;
    outline:1px solid #dce7f8
}
.commentReactionCountBtn{
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.3em;
}
.commentSendBtn{
    width: 1.4em;
    height: 1.4em;
    margin-left: 0.3em;
}
.childCommentDiv{
    margin-left: 1.5rem;
}
.commentSpan {
    font-size: 13px;
    margin-left: 2em;
}
.deleteComment{
    margin-left: 3rem;
    font-size: 0.9rem;
    cursor: pointer;
}
.deletePost{
    font-size: 0.9rem;
    cursor: pointer;
}
.topicTagName{
    color: #277AFC;
}
.sharePostData{
    padding: 1rem;
    border: 1px solid #bcaeae;
    border-radius: 1rem;
}
.sharePostInputBox {
    min-height:3rem;
    width: 100%;
    padding-left: 1rem;
    font-size: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.3rem;
    outline: none;
    border: none;
    border-bottom: 2px solid #c0bbbb;
    resize: none !important;
    overflow: hidden;
}
.dropDownOfChannelList .dropdown-menu.show  {
    display: block !important;
    height: 17rem !important;
    overflow: auto !important;
}
.optionDiv{
    position: relative;
}
.optionList{
    position: absolute;
    padding: 0.4rem;
    list-style-type: none;
    border: 1px solid rgb(255, 255, 255);
    background: #ffff;
    top: 1.5rem;
    right: 34%;
    border-radius: 0.6rem;
}
.optionIndList:hover {
    background-color: #e5e5e5;
}
.userListDataDiv{
    height: 94%;
    overflow: auto;
}
.cancelEditBtn{
    font-size: 0.7em;
    color: rgb(40, 130, 252);
    cursor: pointer;

}
.feedDataDiv a {
    word-break: break-all;
}
.editPostInputBox {
    min-height:3rem;
    width: 100%;
    padding-left: 1rem;
    font-size: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.3rem;
    outline: none;
    border: none;
    border: 2px solid #c0bbbb;
    resize: none !important;
    overflow: hidden;
    border-radius: 0.5rem;
}
.removeItem{
    position: absolute;
    right: 0.5rem;
    color: red;
    top: -0.3rem;
    cursor: pointer;
}
@media screen and (min-width: 1024px) {
    .uploadedPostMediaViewDiv{
        border-radius: 0.6rem;
        border: 1px solid #e0e0e0;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
    }
    .postRightSideDiv{
        display: block;
    }
    .postRightSideDiv .postLeftSideDiv{
        width: 0%;
    }
    .postMediaData{
        width: 100%;
    }
    .postRightSide {
        width: 100%;
    }
    .reactionCountDiv {
        width: 100%;
        padding-left: 1em;
    }
    .postTimingDescription {
        padding-left: 1em;
    }
    .reactionOptionDiv {
        width: 100%;
    }
    .uploadButton {
        height: 2.2rem;
    }
    .postImage {
        max-height: 13.5rem;
    }
    .profileImagePicDivPost{
        padding-right:0 !important;
        padding-left: 0 !important;
    }
}
@media screen and (min-width: 1200px) {
    .uploadedPostMediaViewDiv{
        width: 80%;
    }
}
@media screen and (min-width: 1400px) {
   .uploadedPostMediaViewDiv{
        width: 65%;
    }
}