.topNavBar{
    padding-left: 4em;
    background: #ffff;
}
@media screen and (max-width: 800px) {
    .adminPostData{
        height: 62% !important;
    }
}
@media screen and (min-width: 810px) {
    .adminPostData{
        height: 75% !important;
    }
}