*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
}
#spinnerClass{
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 42%;
    z-index: 10000000 !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.redStar{
  color: red;
}
.PhoneInputInput{
  flex: 1 1;
  min-width: 0;
  padding: 0.3rem;
  border: 1px solid #dbd1d1;
  border-radius: 6px;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.active .adminPanelOptionTitle {
  color: rgb(191,194,195);
  border-bottom:1px solid;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.pagination {
  display: flex;
  flex-wrap: wrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
