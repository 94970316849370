.channelDetails {
    position: relative;
    height: 48%;
}
input[type=text] {
    cursor: auto !important;
}
.indivisualChannelDiv {
    height:92%;
    overflow:auto;
    overflow-x: hidden;
}
.channelBannerImage img {
    width: 100%;
    height: 86%;
}
.channelThumbImage img {
    width: 76%;
    height: 4.6rem;
    border-radius: 1rem;
    border: 1px solid #d3d2d2;
}
.channelName {
    position: relative;
    margin-left: 24%;
}
.channelName h5 {
    font-weight: 600;
    margin: 0;
}
.channelName p {
    font-size: 0.8rem;
}
.postDiv {
    /* overflow: scroll; */
    height:auto;
    padding-top: 1rem;
}

.profileImagePicDiv {
    width:100%;
    text-align: center;
}
.profileImagePicChannel {
    height: 3.2em ;
    width: 3.2em;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229);
}
.uploadButton {
    width:20%;
    text-align: center;
    height: 2.2rem;
}
.uploadPostInputDiv {
    width: 100%;
}
.postInputBox {
    /* min-height:2.2rem !important; */
    min-height:3rem;
    width: 100%;
    border-radius: 0.7rem;
    border: 1px solid #e0e0e0;
    padding-left: 1rem;
    font-size: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.3rem;

}
.postInputBox:focus-visible {
    border-radius: 1rem;
    outline:1px solid #c0cfe6
}
.uploadOptionDiv {
    border: 1px solid #d8cfcf;
    border-radius: 2em;
    height: 2.3em;
    align-items: center;
    position: relative;
    margin-right: 0.4em;
    width:17%;
}
.uploadOptionDiv:hover{
    background-color: #DAE9FF;
    cursor: pointer;
    border:1px solid #c0cfe6;
}
.uploadOptionDiv input {
    opacity:0.01;
    position:absolute;
    width: 100%;
    z-index: 1000;
}
.uploadOptionDivName {
    position:relative;
}
.uploadedPostViewDiv{
    border-radius: 0.6rem;
    border: 1px solid #e0e0e0;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.uploadOptionDiv img {
    width:1.4em;
}
.uploadPostInput {
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    margin-left: 8rem;
}
.filter-div-channel{
    right: 6rem;
    width: auto;
    display: flex;
    justify-content: flex-end !important;
    align-items: baseline !important;
    position: relative;
}
.custom-multiselect-class-channel{
    max-width: 25%;
}
.bannerImageNull{
    height: 83%;
    background: #277AFC;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bannerImagNullName{
    color: white;
    font-size: 13rem;
}
.thumbNailImageNull{
    height: 4.6rem;
    background: #277AFC;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 1rem;
}
.thumbNailImageNullImage{
    color: white;
    font-size: 1rem;
}

@media screen and (min-width: 1000px) {
    .channelBannerImage {
        width: 100%;
        height: 14rem;
        position: relative;
    
    }
    .channelName {
        position: relative;
        margin-left:16%;
    }
    .channelBannerImage img {
        width: 100%;
        height: 12rem;
        object-fit: cover;
    }
    .channelThumbImage {
        position: absolute;
        bottom: 0%;
        margin-left: 4%;
    }
    .channelThumbImage img {
        width: 5rem;
        height: 4.5rem;
        border-radius: 1rem;
        border: 1px solid #d3d2d2;
    }
    .uploadPostDiv {
        border-radius: 0.6rem;
        border: 1px solid #e0e0e0;
        min-height:8.2rem;
        padding: 0 0.5em 0 0.5em;
        height: auto;
        width: 100%;
    }
    .uploadPostInputDiv {
        width: 100%;
        padding-left:0.5rem;
    }
    .uploadOptionDiv {
        margin-right: 0.6em;
        width: 23%;
    }
    .uploadOptionDiv img {
        width:1.4rem;
    }
    .uploadOptionDivName {
        position:relative;
        font-size: 0.8em;
    }
    .uploadedPostViewDiv{
        border-radius: 0.6rem;
        border: 1px solid #e0e0e0;
        padding-left: 1em;
        padding-right: 1em;
    }
    .bannerImageNull{
        height: 83%;
        background: #277AFC;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bannerImagNullName{
        color: white;
        font-size: 7rem;
    }
    .thumbNailImageNull{
        width: 4.2rem;
        height: 4.3rem;
    }
    .thumbNailImageNullImage{
        color: white;
        font-size: 1rem;
    }
    .uploadBtnDiv{
        margin-left: 8rem;
        justify-content: space-between;
        align-items: center;
    }
    .filter-div-channel{
        right: 0;
        width: auto;
        display: flex;
        justify-content: flex-end !important;
        align-items: baseline !important;
        position: relative;
    }
}
@media screen and (min-width: 1200px) {
    .uploadPostDiv {
        border-radius: 0.6rem;
        border: 1px solid #e0e0e0;
        height:8.2rem;
        padding: 0 1em 0 1em;
        height: auto;
        width:80%;
        margin: 0 auto
    }
    .uploadPostInput {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: 0rem;
    }
    .channelBannerImage {
        width: 80%;
        height: 15rem;
        position: relative;
        margin: 0 auto;
    }
    .channelThumbImage {
        width: 6rem;
        position: absolute;
        bottom: 3%;
        margin-left: 11%;
    }
    .uploadOptionDiv {
        width: 24%;
        margin-right: 0.3em;
    }
    .bannerImageNull{
        height: 83%;
        background: #277AFC;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bannerImagNullName{
        color: white;
        font-size: 5rem;
    }
    .thumbNailImageNull{
        width: 86%;
        height: 4.3rem;
    }
    .thumbNailImageNullImage{
        color: white;
        font-size: 1rem;
    }
    .channelThumbImage img {
        width: 82%;
        height: 4rem;
        border-radius: 1rem;
        border: 1px solid #d3d2d2;
    }
    .uploadBtnDiv{
        margin-left: 0;
        justify-content: space-between;
        align-items: center;
    }
    .filter-div-channel{
        right: 5rem;
        width: auto;
        display: flex;
        justify-content: flex-end !important;
        align-items: baseline !important;
        position: relative;
    }
}
@media screen and (min-width: 1400px) {
    .uploadPostDiv {
        border-radius: 0.6rem;
        border: 1px solid #e0e0e0;
        height:8.2rem;
        padding: 0 1em 0 1em;
        height: auto;
        width:65%;
        margin: 0 auto
    }
    .channelBannerImage {
        width: 65%;
        height: 15rem;
        position: relative;
        margin: 0 auto;
    }
    .channelThumbImage {
        width: 10%;
        position: absolute;
        bottom: 2%;
        margin-left: 20%
    }
    .uploadOptionDiv {
        width: 24%;
        margin-right: 0.2em;
    }
    .channelName {
        position: relative;
        margin-left:19%;
    }
    .channelThumbImage img {
        width: 88%;
        height: 4rem;
        border-radius: 1rem;
        border: 1px solid #d3d2d2;
    }
    .filter-div-channel{
        right: 10rem;
        width: auto;
        display: flex;
        justify-content: flex-end !important;
        align-items: baseline !important;
        position: relative;
    }
}