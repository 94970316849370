.managmentScreen{
    background: #F1F7FF;
    height: 100vh;
}
.userManagmentlist {
    background: #fffbfb;
    border: 1px solid gray;
    height: 3rem;
    display: flex;
    align-items: center;
    cursor:pointer;
    border-radius: 1rem;
    font-size: 0.8rem;
}
.showHideBtn {
    outline:none;
    margin-left: -2.7rem;
}
.tab-content{
    height: 94%;
}
.tabClassDiv{
    height: 100%;
    overflow: auto;
}
.userManagmentScreenBox{
    background: #ffff;
    border: 1px solid rgb(198, 188, 188);
    border-radius: 1rem;
    padding: 1rem 1rem 0 1rem;
    height: auto;
    max-height: 80vh;
    overflow: auto;
}
