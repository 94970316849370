.Login {
    height: 100vh;
    background: linear-gradient(90deg,rgb(150 189 240) 42%, rgb(241 250 255) 100%);
}
.loginImage {
    /* background-image: url('../../assets/image/loginPgbgImage.png');
    width: 100vw;
    background-size: cover;
    object-fit: cover; */
    height: 100vh;

}
.loginCard{
    margin-right: auto;
    margin-left: auto;
}
.loginBox {
    padding: 1.5rem 0;
    border-radius:0.9rem;
    background-color:white;
    height: 40%;
}
.singUpTag{
    display: flex;
    flex-wrap: wrap;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.secndBtn{
    color: #2783fc !important;
    background-color:#ffff!important;
    border: 1px solid #2783fc !important;
}
.secndBtn:hover {
    color: #2783fc !important;
    background-color:#c4daf7!important;
    box-shadow: 1px ;
}
.showHideBtnLog{
    outline:none;
    margin-left: -2.7rem;
    align-items: center;
    padding-top: 0.5rem;
}