.eventDataList{
    background: #ffff;
    padding: 1.6rem 0;
    cursor: pointer;
}
.eventDataList ul {
    list-style: none;
}
.eventDataList ul .eventDataDate {
    padding-top: 0.7rem;
    color: #737373;
}
.eventDataList Table {
    overflow: auto;
    line-height: 2em;
    box-shadow: 2px 2px #dae8ea;
    border-radius: 2rem;
    margin-left: 1rem;
}
.eventScreen{
    background: #F1F7FF;
    height: 100vh;
}
.eventScreenPage {
    /* background: #ffff; */
    
}
.eventScreenHeading {
    text-align: center;
    margin-top: 2rem;
}
.eventScreenBox {
    background: #ffff;
    margin-top: 3rem;
    border: 1px solid rgb(198, 188, 188);
    border-radius: 1rem;
    padding: 1rem;
    height: 85vh;
    overflow: auto;
}
.eventScreenBoxEdit {
    background: #ffff;
    margin-top: 3rem;
    border: 1px solid rgb(198, 188, 188);
    border-radius: 1rem;
    padding: 1rem;
    overflow: auto;
}
.tagsInputContainer{
    border: 1px solid black;
    padding: .5em;
    border-radius: 3px;
    width: min(80vw,720px)
}
.tagsItem {
    display: inline-block;
    background-color:  rgb(235, 235, 235);
    padding: .2em .4em;
    margin: .5em .4em;
    border-radius: .5em;
}
.tagsItem .close {
    cursor: pointer;
}
.eventDataList .eventDataTitle {
    color: #559bfe;
}
.eventDataList .eventDataDescription {
    color: #287ffc;
}
.indivisualEvent{
    cursor:pointer;
    height: 100%;
    border-radius: 1em;
    background: #ffff;
    padding-bottom:0.6em ;
    box-shadow: 0em 0em 0px 0px #ffffff;
    
}
.indivisualEvent:hover{
    cursor:pointer;
    height: 100%;
    border-radius: 1em;
    background: #ffff;
    padding-bottom:0.6em ;
    box-shadow: 3px 4px 10px 0px #b7bccd;
}
.indivisualEventImage{
    text-align: center;
}
.indivisualEventImage img{
    width: 96%;
    height: min(9rem);
    padding: 0.6em 0.3em;
    border-radius: 1.5em;
    object-fit: contain;
}
.indivisualEvent .indivisualEventPageinfo {
    padding: 0 0.8em;
}
.indivisualEvent .indivisualEventDate {
    font-size: 15px;
}
.indivisualEvent .indivisualEventLocation {
    font-size: 15px;
}
.modelEventPageinfo {
    padding: 0 1.3em;
}
.modelEventPageinfo  strong {
    font-size: 1rem;
}
.modelEventPageinfo  p {
    font-size: 0.9rem;
    margin: 0;
}
.modeEventImage {
    text-align: center;
}
.modeEventImage img {
    width: auto;
    height: 12rem;
    padding: 0.6em 0.3em;
    border-radius: 1.5em;
    object-fit: contain;
}
.modal.show .modal-dialog {
    justify-content :center !important;

}
.modal-content {
    /* width: 80% !important; */
}
.modelSpeakerDetail ul {
    list-style: none;
    padding-left: 1.8em;
    margin-bottom: 0.3em;
}
.modelSpeakerDetail .modelSpeakerDetailSpeaker li {
    margin-right: 0.8em;
    color: #488dee;
}
.modelSpeakerDetailTopic span {
    margin-right: 0.8em;
    color: #559bfe;
}
.eventDataDiv {
    height:83%;
    overflow: auto;
    overflow-x: hidden;
}
.searchBttn {
    text-align: end;
    flex: 1;
    padding: 0 1.7em 0 0 !important;
}
.addEventBtn {
    margin: 0 1em;
}
.previewBtn{
    border-radius: 0.5rem;
    border:1px solid;
    padding: 0.2rem;
    background:#277AFC;
    color:white;
}
.previewImageModal{
    width: 100%;
    height: auto;
    /* max-height: 100%; */
}
.modelEventdescriptionDiv{
    word-wrap: break-word;
    font-size: 0.9rem !important;
}
.badge{
    color: #ffff !important;
}
@media screen and (max-width: 1024px) {
    .eventDataDiv{
        height:76%;
    }
    .addEventBtn {
        margin: 0;
    }
    .searchBttn {
        margin-top: 1.3em !important;
        text-align: start;
    }
}
@media screen and (min-width: 1200px) { 
    .eventDataDiv{
        height:79% !important;
    }
}
@media screen and (min-width: 1024px) {
    .eventDataDiv{
        height:72%;
    }
}
@media screen and (max-width: 1400px) {
    .searchBttn {
        /* margin-top: 1em !important; */
    }
}
@media screen and (min-width: 1403px) {
    .startEndDate {
        padding-top: 1em !important;
    }
    .eventDataDiv{
        height:82% !important;
    }
}
@media screen and (max-width: 800px) {
    .indivisualEventImage img{
        height: min(17vh,220px);
    }
    .indivisualEvent .indivisualEventPageinfo {
        font-size: 14px;
    }
}
