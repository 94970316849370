
.postPageData{
    height: 93%;
}
.adminPostDiv {
    height:98%;
    overflow: auto;
}
.adminFeedData{
    height: auto;
    /* overflow: auto; */
}
.uploadAdminPostDiv {
    border-radius: 0.6rem;
    border: 1px solid #e0e0e0;
    height:8.2rem;
    padding: 0 1em 0 1em;
    height: auto;
    width: 65%;
    margin: 0 auto;
}
.profileImagePicDiv {
    width:100%;
    text-align: center;
}
.profileImagePic {
    height: 3.3rem;
    width: 3.3rem;
    border-radius: 50%;
    border: 1px solid rgb(237, 229, 229);
}
.adminPostuploadButton {
    height: 2.3rem;
    margin-top: 0.6em;
    /* margin-left:1em; */
}
.uploadAdminPostInputDiv {
    width: 100%;
    padding-left:0.8em;
}
.postInputBox {
    /* min-height:2.2rem !important; */
    min-height:3rem;
    width: 100%;
    border-radius: 0.7rem;
    border: 1px solid #e0e0e0;
    padding-left: 1rem;
    font-size: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.3rem;

}
.postInputBox:focus-visible {
    border-radius: 1rem;
    outline:1px solid #c0cfe6
}
.AdminUploadOptionDiv {
    border: 1px solid #d8cfcf;
    width: 17%;
    border-radius: 2em;
    height: 2.3em;
    align-items: center;
    position: relative;
    margin-right: 0.3rem !important;
}
.AdminUploadOptionVideoDiv{
    border: 1px solid #d8cfcf;
    width: 100%;
    border-radius: 2em;
    height: 2.3em;
    align-items: center;
    position: relative;
    margin-top: 0.4rem;
}
.AdminUploadOptionVideoMainDiv{
    width: 17%;
}
.rmsc{
    flex: 1;
}
.custom-multiselect-class{
    margin-left: 1rem;
}
.AdminUploadOptionDiv:hover{
    background-color: #DAE9FF;
    cursor: pointer;
    border:1px solid #c0cfe6;
}
.AdminUploadOptionDiv input {
    opacity:0.01;
    position:absolute;
    width: 100%;
    z-index: 10;
    cursor: pointer !important;
}
.AdminUploadOptionVideoDiv input {
    opacity:0.01;
    position:absolute;
    width: 100%;
    z-index: 10;
    cursor: pointer !important;
}
.dropdown-content {
    z-index: 100 !important;
}
.AdminUploadOptionVideoDiv:hover{
    background-color: #DAE9FF;
    cursor: pointer;
    border:1px solid #c0cfe6;
}
.uploadAdminPostInputDivRow{
    justify-content: end;
    flex-wrap: wrap;
}
.AdminUploadOptionDiv img {
    width:1.5em;
}
.uploadOptionDivName {
    position:relative;
}
.sponsoredBtn{
    width: 80%;
}
.adminPostuploadButtonDiv{
    width: 13%;
}
.customDropdown{
        position: absolute;
    top: 23%;
    right: 1%;
    height: 12px;
    /* transform: translate(-50%, -50%); */
    /* width: 12px; */
    font-size: 33px;
    color: gray;
    font-weight: 100;
}
.sponsoredBtn .dropdown {
    width: 25% !important;
}
.filterDataDiv{
    flex-wrap: wrap !important;
}
.searchAdminPost{
    position: relative;
    border-radius: 0.6rem;
    border: 1px solid #e0e0e0;
    height:8.2rem;
    /* padding: 0 1em 0 1em; */
    height: auto;
    width: 55%;
    margin: 0 auto;
}
.searchIcon {
    position: absolute;
    right:11px;
    top:5px;
    z-index: 100;
}
.filter-search-div{
    justify-content: space-between;
    background: transparent !important;
    border: none;
}
.filter-div{
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-left: 4rem;  
}
.search-div{
    height: 1rem;
    flex: 1;
}
.adminUploadVideoForm{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.uploadAdminPostInputDiv img {
    width:1.4em;
}
@media screen and (min-width: 1540px) {
    .sponsoredBtn .dropdown {
        margin-left: 0.3em;
        margin-top: 0.3em;  
        width: 36%;
        text-align: center;
    }
    .sponsoredBtn {
        justify-content: space-between!important;
        margin-top: 0.3em !important;
    }
    .sponsoredBtn .dropdown .dropdown-toggle {
        width: 88%;
    }
    .sponsoredBtn .adminPostFIlterButton {
        margin-left: 1.3em;
        height: 2.4em;
        margin-top: 0.6em;
        width: 24%;
    }
    .AdminUploadOptionDiv{
        margin-top: 0.3em !important;
    }
    .uploadAdminPostDiv{
        width: 65% !important;
    }
    .searchAdminPost{
        width: 65% !important;
    }
    .uploadAdminPostInputDiv img {
        width: 1.2em !important;
    }

}
@media screen and (min-width: 1024px) {
    .adminPostDiv {
        height:97%;
        overflow: auto;
    }
    .uploadAdminPostInputDiv {
        width: 100%;
    }
    .sponsoredBtn {
        margin-top: 0.3em;
        padding: 0px;
        justify-content: space-evenly!important;
    }
    .adminPostuploadButton {
        height: 2.3rem;
        margin-top: 0.8em;
        width: 100%;
    }
    .AdminUploadOptionDiv {
        margin-right: 0 ;
        margin-top: 0.3em !important;
        width: 22%;
    }
    .AdminUploadOptionVideoMainDiv{
        width: 22%;
    }
    .sponsoredBtn .dropdown {
        margin-left: 0.3em;
        margin-top: 0.6em;  
        text-align: center;
        width: fit-content;
    }
    .sponsoredBtn .adminPostFIlterButton {
        margin-left: 1em;
        height: 2.4em;
        margin-top: 0.6em;
        width: 20%;
    }
    .uploadAdminPostDiv {
        width:100%;
    }
    .searchAdminPost{
        width: 100%;
    }
}
@media screen and (min-width: 1200px) {
    .uploadAdminPostInputDivRow{
        justify-content:start
    }
    .uploadAdminPostInputDiv img {
        width:1.2em;
    }
    .sponsoredBtn .dropdown {
        margin-left: 0.3em;  
        text-align: center;
        margin-top: 0.4em;
    }
    .sponsoredBtn .adminPostFIlterButton {
        margin-left: 1em;
        margin-top: 0.4em;
        width: 20%;
    }
    .uploadAdminPostDiv {
        width:80%;
    }
    .searchAdminPost{
        width: 80%;
    }
}
@media screen and (min-width: 1400px) {
    .uploadAdminPostDiv {
        width:65%;
    }
    .searchAdminPost{
        width: 35%;
    }
}
