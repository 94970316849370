.userInformation{
    background: #F1F7FF;
    height: 100vh;
}
.editUserInfotScreenHeading {
    text-align: center;
    margin-top: 2rem;
}
.editUserInfoScreenBoxEdit {
    background: #ffff;
    margin-top: 3rem;
    border: 1px solid rgb(198, 188, 188);
    border-radius: 1rem;
    padding: 1rem;
    overflow: auto;
    width: 92%;
    justify-content: center;
    align-items: center;
    margin: 0 auto !important;
}
.editProfilePhoto{
    width: 150px;
    height: 150px;
    margin: 1rem;
}
.profilePhotoDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}