.tableRolePermissionClass{
    height: 95%;
    overflow:auto !important;
    padding: 1rem 1.2rem;
    background-color:#ffff;
    border: 1px solid #cac8c8;
    margin-left: 1rem;
    border-radius: 1.3rem;
    margin-top: 1em;
}
.editIcon{
    color: green;
    cursor: pointer;
}
.roleBadge{
    height: 1.8rem;
}
.internalPermission{
    font-size: 0.9rem;
    font-weight: 500;
}
.css-1p3m7a8-multiValue {
    display: flex;
    min-width: 0;
    --bs-bg-opacity: 1;
    --bs-badge-color: #fff;
    color: var(--bs-badge-color);
    background-color: rgba(var(--bs-primary-rgb),var(--bs-bg-opacity))!important;
    border-radius: 2px;
    margin: 2px;
    box-sizing: border-box;
    
}
.css-wsp0cs-MultiValueGeneric{
    --bs-badge-color: #fff;
    color: var(--bs-badge-color) !important;
}
.css-9jq23d{
    color: #ffff !important;
}