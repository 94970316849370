.feedDataHeading{
    color: steelblue;
    margin: 0 0 0 18%;
}
.userFeedChannelList {
    height: 100%;
    overflow: auto;
    margin-bottom: 0.6em;
    margin: 0 auto;
    width: 65%;
}
.userFeedChannelList .userFeedChannelsListLogo {
    border-radius: 50%;
    height: 3.5rem;
    width: 3.6rem;
}
.userFeedChannelList li {
    border: 2px solid rgb(198, 196, 196);
    border-radius: 1rem;
}
.userFeedChannelsInfo {
    cursor: pointer;
}